import { render, staticRenderFns } from "./PcMain.vue?vue&type=template&id=93759d1e&scoped=true&xmlns%3Ael-col=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml&"
import script from "./PcMain.vue?vue&type=script&lang=js&"
export * from "./PcMain.vue?vue&type=script&lang=js&"
import style0 from "./PcMain.vue?vue&type=style&index=0&id=93759d1e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93759d1e",
  null
  
)

export default component.exports